// src/components/LeadsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [notification, setNotification] = useState(null);

  // Fetch leads from API on component mount
  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await axios.get('https://api.crm.sugarprojects.com/wp-json/wp/v2/leads');
      setLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  // Handle input changes, including nested `acf` fields
  const handleInputChange = (id, field, value) => {
    setLeads(leads.map(lead =>
      lead.id === id ? { ...lead, acf: { ...lead.acf, [field]: value } } : lead
    ));
  };

  // Save or update a single lead
  const saveLead = async (lead) => {
    const token = localStorage.getItem("token");

    try {
      // Prepare the data payload with `title` set as the `name`
      const data = {
        title: lead.acf.name || "Untitled Lead",  // Set title as the lead's name
        acf: lead.acf,
        status: "publish"
      };

      if (lead.id) {
        // If the lead has an ID, update it
        await axios.put(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/leads/${lead.id}`,
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        // If lead is new (no ID), create it
        const response = await axios.post(
          'https://api.crm.sugarprojects.com/wp-json/wp/v2/leads',
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // Update the lead ID after creation
        lead.id = response.data.id;
      }
    } catch (error) {
      console.error('Error saving lead:', error);
    }
  };


  // Save all leads
  const saveAllLeads = async () => {
    setIsSaving(true);
    try {
      await Promise.all(leads.map(saveLead)); // Save each lead
      setNotification({ message: "All leads saved successfully!", type: "success" });
    } catch (error) {
      console.error('Error saving all leads:', error);
    } finally {
      setIsSaving(false);
    }
  };

  // Add a new lead with default values
  const addNewLead = () => {
    const newLead = {
      acf: {
        name: '',
        email: '',
        phone: '',
        website: '',
        first_contact: new Date().toISOString().split('T')[0],
        last_contact: new Date().toISOString().split('T')[0],
        details: '',
      },
    };
    setLeads([newLead, ...leads]);
  };

  // Filter leads based on search term
  const filteredLeads = leads.filter(
    lead => Object.values(lead.acf || {}).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Delete a lead
  const deleteLead = async (lead) => {
    const token = localStorage.getItem("token");

    if (!lead.id) {
      // If the lead doesn't have an ID (i.e., it's unsaved), simply remove it from the state
      setLeads(prevLeads => prevLeads.filter(l => l !== lead));
      return;
    }

    try {
      // Send delete request to the API
      await axios.delete(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/leads/${lead.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Remove the lead from the local state
      setLeads(prevLeads => prevLeads.filter(l => l.id !== lead.id));

      setNotification({ message: "Lead deleted successfully!", type: "success" });
    } catch (error) {
      console.error("Error deleting lead:", error);
      setNotification({ message: "Failed to delete lead.", type: "error" });
    }
  };


  return (
    <div className="bg-white shadow sm:rounded-lg p-4">
      {/* Notification Display */}
      {notification && (
        <div aria-live="assertive" className="pointer-events-none fixed top-12 right-0 w-full flex items-end px-4 py-6 sm:items-start sm:p-6 z-10">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition ease-out duration-300 ${notification.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
              <div className="p-4">
                <div className="flex items-start">
                  <div className="shrink-0">
                    {/* Conditional icon based on notification type */}
                    <svg className={`h-6 w-6 ${notification.type === 'error' ? 'text-red-400' : 'text-green-400'}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15L15 9.75M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z" />
                    </svg>
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                  </div>
                  <div className="ml-4 flex shrink-0">
                    <button onClick={() => setNotification(null)} type="button" className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Close</span>
                      <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="border-b border-gray-200 bg-white p-4 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Leads</h1>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <button
              onClick={addNewLead}
              className="mr-4 p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow"
            >
              Add New Lead
            </button>
            <button
              onClick={saveAllLeads}
              disabled={isSaving}
              className="p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      {/* Search bar */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search leads..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500"
        />
      </div>

      {/* Lead cards */}
      <div className="space-y-6">
        {filteredLeads.map((lead, index) => (
          <div key={index} className="p-4 bg-gray-100 shadow rounded">
            <div className='flex align-center justify-between'>
              <h2 className="text-xl font-semibold text-pink-600">
                {lead.acf?.name || 'New Lead'}
              </h2>
              <button className='text-red-600 hover:text-red-400' onClick={() => deleteLead(lead)}>
                Delete
              </button>
            </div>

            {/* Editable fields with labels */}
            <div className="grid grid-cols-3 gap-4">
              <label className="col-span-1">
                Name
                <input
                  type="text"
                  value={lead.acf?.name || ''}
                  onChange={(e) => handleInputChange(lead.id, 'name', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-1">
                Email
                <input
                  type="text"
                  value={lead.acf?.email || ''}
                  onChange={(e) => handleInputChange(lead.id, 'email', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-1">
                Phone
                <input
                  type="text"
                  value={lead.acf?.phone || ''}
                  onChange={(e) => handleInputChange(lead.id, 'phone', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-1">
                Website
                <input
                  type="text"
                  value={lead.acf?.website || ''}
                  onChange={(e) => handleInputChange(lead.id, 'website', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-1">
                First Contact
                <input
                  type="date"
                  value={lead.acf?.first_contact || ''}
                  onChange={(e) => handleInputChange(lead.id, 'first_contact', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-1">
                Last Contact
                <input
                  type="date"
                  value={lead.acf?.last_contact || ''}
                  onChange={(e) => handleInputChange(lead.id, 'last_contact', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-3">
                Details
                <textarea
                  value={lead.acf?.details || ''}
                  onChange={(e) => handleInputChange(lead.id, 'details', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadsPage;