import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';

const ContactList = ({ websites, unique, cols }) => {
  if (websites) {
    // Create a new array to hold contacts with associated logo and website title
    var contactDetails = websites.flatMap(website =>
      (website.acf.contacts || []).map(contact => ({
        name: contact.name || "No name provided",
        email: contact.email,
        phone_1: contact.phone_1,
        phone_2: contact.phone_2,
        position: contact.position || "No position specified",
        logo: website.acf.logo,              // Website logo
        websiteTitle: website.title.rendered  // Website title for alt text
      }))
    );

    if(unique){
      // Remove duplicates by 'email'
      contactDetails = [
        ...new Map(
          contactDetails.map(contact => [contact.email, contact]) // 'email' serves as the unique identifier
        ).values(),
      ];
    }

    return (
      <div className="mt-6">
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
          {contactDetails.map((contact, index) => (
            <li key={index} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">{contact.name}</h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-700">{contact.position}</p>
                  <p className="mt-1 truncate text-sm text-gray-500">{contact.phone_1}</p>
                  <p className="mt-1 truncate text-sm text-gray-500">{contact.phone_2}</p>
                </div>
                <img 
                  alt={`${contact.websiteTitle || "Website"} logo`} 
                  src={contact.logo} 
                  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" 
                />
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <a
                      href={`mailto:${contact.email}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                      Email
                    </a>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <a
                      href={`tel:${contact.phone_1}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return <div>No contacts available</div>;
  }
};

export default ContactList;
