import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import LogTable from "./LogTable";
import axios from 'axios';

const JobPage = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [logs, setLogs] = useState([]);
    const [websites, setWebsites] = useState([]); // To store website options
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Local state for form inputs
    const [title, setTitle] = useState("");
    const [onedriveLink, setOnedriveLink] = useState("");
    const [selectedWebsite, setSelectedWebsite] = useState("");
    const [targetHours, setTargetHours] = useState("40");
    const [status, setStatus] = useState(""); // New state for job status

    // Fetch job data by jobId
    useEffect(() => {
        if (job && job.acf.target_hours) {
            setTargetHours(job.acf.target_hours); // Set initial target hours
        }
        console.log(job)
    }, [job]);


    // Fetch job data by jobId
    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await axios.get(`https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${jobId}`);
                setJob(response.data);
                setLogs(response.data.acf.logs || []);
                setTitle(response.data.title.rendered || "");
                setOnedriveLink(response.data.acf.onedrive_link || "");
                setSelectedWebsite(response.data.acf.website || "");
                setStatus(response.data.acf.status || ""); // Set initial status
                setLoading(false);
            } catch (err) {
                console.error("Error fetching job data:", err);
                setError("Failed to load job data.");
                setLoading(false);
            }
        };

        // Fetch websites for dropdown options
        const fetchWebsites = async () => {
            try {
                const response = await axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/websites");
                setWebsites(response.data);
            } catch (err) {
                console.error("Error fetching websites:", err);
            }
        };

        fetchJob();
        fetchWebsites();
    }, [jobId]);

    const handleSaveChanges = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${jobId}`,
                {
                    acf: {
                        logs, // Save updated logs
                        target_hours: targetHours,
                        status, // Save updated status
                        website: selectedWebsite, // Save updated website
                        onedrive_link: onedriveLink // Save updated OneDrive link
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            alert("Changes saved successfully!");
        } catch (err) {
            console.error("Error saving job data:", err);
            alert("Failed to save changes.");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="bg-white shadow sm:rounded-lg p-4">
            <div className="m-4 flex justify-between align-center">
                <h1 className="text-2xl font-semibold leading-6 text-gray-900">{title}</h1>
                <button
                    onClick={handleSaveChanges}
                    className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                >
                    Save Changes
                </button>
            </div>
            <div className="flex w-full justify-between">
                <div className="w-[23%]">
                    <label htmlFor="job-name" className="block text-sm font-medium text-gray-900">
                        Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    />
                </div>
                <div className="w-[23%]">
                    <label htmlFor="onedrive" className="block text-sm font-medium text-gray-900">
                        Onedrive
                    </label>
                    <input
                        type="text"
                        id="onedrive"
                        value={onedriveLink}
                        onChange={(e) => setOnedriveLink(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    />
                </div>
                <div className="w-[23%]">
                    <label htmlFor="website" className="block text-sm font-medium text-gray-900">
                        Website
                    </label>
                    <select
                        id="website"
                        value={selectedWebsite}
                        onChange={(e) => setSelectedWebsite(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    >
                        <option value="">Select Website</option>
                        {websites.map((website) => (
                            <option key={website.id} value={website.id}>
                                {website.title.rendered}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="w-[23%]">
                    <label htmlFor="status" className="block text-sm font-medium text-gray-900">
                        Status
                    </label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    >
                        <option value="">Select Status</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="completed">Completed</option>
                    </select>
                </div>
            </div>
            <LogTable logs={logs} setLogs={setLogs} targetHours={targetHours} setTargetHours={setTargetHours} />
        </div>
    );
};

export default JobPage;