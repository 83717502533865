import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import WebsiteListComponent from "./WebsiteListComponent";
import ContactListComponent from "./ContactListComponent";
import NotesComponent from "./NotesComponent";
import WebsiteDrawer from "./WebsiteDrawer";
import { useData } from "./DataContext"; // Import the useData hook

const ClientPage = ({ clientId }) => {
  const {
    clients,
    websites,
    fetchData,
    addWebsite,
    deleteWebsite,
    addNote,
  } = useData();

  console.log(clientId)
  const client = clients.find(client => client.id === parseInt(clientId)); // Find client by ID

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userImages, setUserImages] = useState({});

  // Fetch user images only when needed
  useEffect(() => {
    const fetchUserImages = async () => {
      try {
        const response = await axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/users");
        const images = {};
        response.data.forEach(user => {
          if (user.acf && user.acf.user_image) {
            const firstName = user.name.split(" ")[0];
            images[firstName] = user.acf.user_image;
          }
        });
        setUserImages(images);
      } catch (error) {
        console.error("Error fetching user images:", error);
      }
    };
    fetchUserImages();
  }, []);

  // UseEffect to fetch client data if not already fetched
  useEffect(() => {
    if (!clients || !clients.length) {
      fetchData();
    }
  }, [fetchData, clients]);

  if (!client) {
    return <p>Loading client data...</p>;
  }

  const handleAddNote = async (noteText) => {
    addNote(client.id, "client", noteText);
  };

  const handleAddWebsite = (websiteData) => {
    addWebsite(websiteData, client.id); // Use the context to add the website
    setIsDrawerOpen(false);
  };

  const handleDeleteWebsite = (websiteId) => {
    deleteWebsite(websiteId, client.id); // Use the context to delete the website
  };

  // Filter websites related to the selected client, with null checks
const clientWebsites = websites?.filter(website =>
  client?.acf?.websites?.includes(website.id)  // Ensure that client.acf.websites is not null or undefined
) || [];  // Fallback to an empty array if websites or client.acf.websites is null or undefined


  // Extract unique contacts from websites
  const getUniqueContacts = (websites) => [
    ...new Map(
      websites
        .flatMap((website) =>
          (website.acf.contacts || []).map((contact) => ({
            ...contact,
            logo: website.acf.logo,
            websiteTitle: website.title?.rendered,
          }))
        )
        .map((contact) => [contact.email, contact])
    ).values(),
  ];

  const uniqueContacts = getUniqueContacts(clientWebsites);

  return (
    <div>
      <h2 className="text-2xl font-semibold">{client?.title?.rendered}</h2>
      <p>{client?.content?.rendered}</p>

      {/* Render the list of websites */}
      <div className="flex justify-between mb-6">
        <h3 className="text-xl font-semibold text-gray-900 mt-6">Websites:</h3>
        <div className="ml-4 mt-4 flex-shrink-0">
          <button
            type="button"
            onClick={() => setIsDrawerOpen(true)}
            className="relative inline-flex items-center rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
          >
            Add website
          </button>
        </div>
      </div>

      <WebsiteListComponent
        websites={clientWebsites}  // Pass only the websites related to the client
        showSearchBar={false}
        delete={true}
        onDelete={handleDeleteWebsite}
      />

      <h3 className="text-xl font-semibold text-gray-900 mt-6">Contacts:</h3>
      <ContactListComponent websites={clientWebsites} unique={true} cols={2} />

      <h3 className="text-xl font-semibold text-gray-900 mt-6">Notes:</h3>
      <NotesComponent notes={client.acf.notes || []} handleAddNote={handleAddNote} userImages={userImages} />

      {/* Use WebsiteDrawer instead of Modal */}
      <WebsiteDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={handleAddWebsite}
        client={client.id}
      />
    </div>
  );
};

export default ClientPage;