// src/components/SOPsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SOPsPage = () => {
  const [sops, setSOPs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sopToDelete, setSopToDelete] = useState(null);

  useEffect(() => {
    fetchSOPs();
  }, []);

  const fetchSOPs = async () => {
    try {
      const response = await axios.get('https://api.crm.sugarprojects.com/wp-json/wp/v2/sops');
      setSOPs(response.data.map(sop => ({
        ...sop,
        acf: {
          ...sop.acf,
          title: sop.title?.rendered || sop.acf?.title || "Untitled SOP" // Ensure title is accessible in acf
        }
      })));
    } catch (error) {
      console.error('Error fetching SOPs:', error);
      setNotification({ message: 'Error fetching SOPs', type: 'error' });
    }
  };

  const handleInputChange = (id, field, value) => {
    setSOPs(sops.map(sop =>
      sop.id === id ? { ...sop, acf: { ...sop.acf, [field]: value } } : sop
    ));
  };

  const saveSOP = async (sop) => {
    const token = localStorage.getItem("token");

    try {
      const data = {
        title: sop.acf.title || "Untitled SOP",
        acf: sop.acf,
        status: "publish"
      };

      if (sop.id) {
        await axios.put(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/sops/${sop.id}`,
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        const response = await axios.post(
          'https://api.crm.sugarprojects.com/wp-json/wp/v2/sops',
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        sop.id = response.data.id;
      }
      setNotification({ message: `SOP "${sop.acf.title}" saved successfully!`, type: 'success' });
    } catch (error) {
      console.error('Error saving SOP:', error);
      setNotification({ message: 'Error saving SOP', type: 'error' });
    }
  };

  const saveAllSOPs = async () => {
    setIsSaving(true);
    try {
      await Promise.all(sops.map(saveSOP));
      setNotification({ message: "All SOPs saved successfully!", type: "success" });
    } catch (error) {
      console.error('Error saving all SOPs:', error);
      setNotification({ message: 'Error saving all SOPs', type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const addNewSOP = () => {
    const newSOP = {
      acf: {
        title: '',
        date_added: new Date().toISOString().split('T')[0],
        instructions: '',
      },
    };
    setSOPs([newSOP, ...sops]);
  };

  const openDeleteModal = (sop) => {
    setSopToDelete(sop);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSopToDelete(null);
    setShowDeleteModal(false);
  };

  const confirmDeleteSOP = async () => {
    const sop = sopToDelete;
    const token = localStorage.getItem("token");

    try {
      if (sop.id) {
        await axios.delete(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/sops/${sop.id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      setSOPs(sops.filter(item => item.id !== sop.id));
      setNotification({ message: `SOP "${sop.acf.title}" deleted successfully!`, type: 'success' });
    } catch (error) {
      console.error('Error deleting SOP:', error);
      setNotification({ message: 'Error deleting SOP', type: 'error' });
    } finally {
      closeDeleteModal();
    }
  };

  const filteredSOPs = sops.filter(
    sop => Object.values(sop.acf || {}).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="bg-white shadow sm:rounded-lg p-4">
      {notification && (
        <div aria-live="assertive" className="pointer-events-none fixed top-12 right-0 w-full flex items-end px-4 py-6 sm:items-start sm:p-6 z-10">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition ease-out duration-300 ${notification.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
              <div className="p-4">
                <div className="flex items-start">
                  <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="border-b border-gray-200 bg-white p-4 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Standard Operating Procedures</h1>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <button onClick={addNewSOP} className="mr-4 p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow">Add New SOP</button>
            <button onClick={saveAllSOPs} disabled={isSaving} className="p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow">
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <input type="text" placeholder="Search SOPs..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500" />
      </div>

      <div className="space-y-6">
        {filteredSOPs.map((sop, index) => (
          <div key={index} className="p-4 bg-white border-t-2 border-gray-200 relative">
            <div className="flex align-center justify-between">
              <h2 className="text-xl font-semibold text-pink-600">{sop.acf.title || 'New SOP'}</h2>
              <button
                onClick={() => openDeleteModal(sop)}
                className="text-red-600 hover:text-red-800"
              >
                Delete
              </button>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <label className="col-span-1">
                Title
                <input
                  type="text"
                  value={sop.acf.title || ''}
                  onChange={(e) => handleInputChange(sop.id, 'title', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-1">
                Date Added
                <input
                  type="date"
                  value={sop.acf.date_added || ''}
                  onChange={(e) => handleInputChange(sop.id, 'date_added', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>

              <label className="col-span-2">
                Instructions
                <textarea
                  value={sop.acf.instructions || ''}
                  onChange={(e) => handleInputChange(sop.id, 'instructions', e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                />
              </label>
            </div>
          </div>
        ))}
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg text-center max-w-sm mx-auto">
            <h3 className="text-lg font-semibold">Are you sure?</h3>
            <p className="my-4">Do you really want to delete "{sopToDelete?.acf.title}"?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={confirmDeleteSOP}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500"
              >
                Yes, delete
              </button>
              <button
                onClick={closeDeleteModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SOPsPage;