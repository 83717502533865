import React, { useState } from 'react';
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const JobDrawer = ({ isOpen, onClose, onSubmit, websites }) => {
  const [jobName, setJobName] = useState('');
  const [selectedWebsite, setSelectedWebsite] = useState('');

  const handleAddJob = (e) => {
    e.preventDefault();

    // Ensure job name and website are provided
    if (jobName.trim() && selectedWebsite) {
      onSubmit({
        name: jobName,         // Pass job title
        website: selectedWebsite // Pass website ID
      });
      setJobName('');           // Clear form fields
      setSelectedWebsite('');
      onClose();                // Close the drawer
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-40" />

      {/* Drawer container */}
      <div className="fixed inset-0 overflow-hidden z-50">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition-all">
              <form onSubmit={handleAddJob} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="bg-pink-700 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold text-white">Add New Job</DialogTitle>
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-md bg-pink-700 text-pink-200 hover:text-white"
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <p className="mt-1 text-sm text-pink-300">
                    Enter the name of the new Job you want to add.
                  </p>
                </div>
                <div className="flex flex-1 flex-col justify-between">
                  <div className="px-4 py-5 sm:p-6">
                    <div>
                      <label htmlFor="job-name" className="block text-sm font-medium text-gray-900">
                        Job Name
                      </label>
                      <input
                        type="text"
                        id="job-name"
                        value={jobName}
                        onChange={(e) => setJobName(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                      />
                    </div>

                    <div className="mt-4">
                      <label htmlFor="website-select" className="block text-sm font-medium text-gray-900">
                        Assign Website
                      </label>
                      <select
                        id="website-select"
                        value={selectedWebsite}
                        onChange={(e) => setSelectedWebsite(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                      >
                        <option value="" disabled>Select a website</option>
                        {websites.map((website) => (
                          <option key={website.id} value={website.id}>
                            {website.title.rendered}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex justify-end p-4">
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-4 rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                    >
                      Add Job
                    </button>
                  </div>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default JobDrawer;