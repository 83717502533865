import React, { useEffect, useState } from "react";
import axios from "axios";
import WebsiteDrawer from "./WebsiteDrawer"; // Update to import WebsiteDrawer
import { Link } from 'react-router-dom';
import WebsitesListComponent from './WebsiteListComponent';
import { useData } from './DataContext'; // Import the context

const WebsitesPage = () => {
  const { websites, setWebsites, setError } = useData();  // Destructure from context
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Renamed for clarity
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredWebsites, setFilteredWebsites] = useState(websites);

  // Fetch websites on component mount
  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?_embed&acf_format=standard&per_page=100"
        );

        if (Array.isArray(response.data)) {
          setWebsites(response.data);  // Set websites in context
          setFilteredWebsites(response.data); // Initialize filtered websites
        } else {
          setWebsites([]); // Handle case where no websites are returned
        }

        // Check for pagination
        const totalPages = response.headers['x-wp-totalpages'];
        if (totalPages > 1) {
          // Fetch additional pages if necessary
          for (let page = 2; page <= totalPages; page++) {
            const additionalResponse = await axios.get(
              `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?_embed&acf_format=standard&per_page=100&page=${page}`
            );
            setWebsites(prevWebsites => [...prevWebsites, ...additionalResponse.data]);
            setFilteredWebsites(prevWebsites => [...prevWebsites, ...additionalResponse.data]);
          }
        }
      } catch (err) {
        setError("Failed to fetch websites");
      } finally {
        setLoading(false);
      }
    };

    fetchWebsites();
  }, [setWebsites, setError]);

  // Filter websites based on search query
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const results = websites.filter(website => {
      const { title, acf } = website;
      const matchesTitle = title.rendered.toLowerCase().includes(lowercasedQuery);
      const matchesDescription = acf?.description?.toLowerCase().includes(lowercasedQuery);
      const matchesCategory = acf?.category?.toLowerCase().includes(lowercasedQuery);
      const matchesClient = acf?.client_name?.toLowerCase().includes(lowercasedQuery);
      return matchesTitle || matchesDescription || matchesCategory || matchesClient;
    });

    setFilteredWebsites(results);  // Update filtered websites
  }, [searchQuery, websites]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Websites</h1>
            <p className="mt-1 text-sm text-gray-500">List of current Sugarwebsites websites.</p>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <button
              type="button"
              onClick={() => setIsDrawerOpen(true)} // Opens the drawer
              className="relative inline-flex items-center rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
            >
              Add website
            </button>
          </div>
        </div>
      </div>

      {/* Use WebsitesListComponent to display websites */}
      <WebsitesListComponent websites={filteredWebsites} delete={true} showSearchBar={true} />

      {/* Use WebsiteDrawer instead of Modal */}
      <WebsiteDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </div>
  );
};

export default WebsitesPage;
