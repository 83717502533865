import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, Label, Switch } from '@headlessui/react'
import { useMaintenanceCount } from './MaintenanceCountContext';


const MaintanenceList = ({ websites, setWebsites, delete: isDeleteEnabled, onDelete, showSearchBar }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredWebsites, setFilteredWebsites] = useState(websites);
    const [notification, setNotification] = useState(null);
    const [enabled, setEnabled] = useState(false)
    const { fetchMaintenanceCount } = useMaintenanceCount();

    // Calculate days since a specific date
    const daysSince = (dateString) => {
        let date;

        // If the date is in ISO format (yyyy-mm-dd)
        if (dateString.includes('-')) {
            date = new Date(dateString); // directly create Date from ISO string
        } else {
            // If the date is in dd/mm/yyyy format
            const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
            date = new Date(Date.UTC(year, month - 1, day)); // month - 1 because months are 0-indexed in JavaScript
        }

        // Get today's date and set both dates to midnight (UTC) to avoid time zone discrepancies
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        // Set the target date to midnight (UTC) as well
        date.setUTCHours(0, 0, 0, 0);

        // Calculate the difference in time
        const diffTime = today - date;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    };

    // Filter websites based on search query, maintenance status, and time since maintenance
    useEffect(() => {
        let results = websites.filter(website => {
            const { title, acf } = website;
            const matchesTitle = title.rendered.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesDescription = acf?.description?.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesCategory = acf?.category?.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesClient = acf?.client_name?.toLowerCase().includes(searchQuery.toLowerCase());
            
            // Only include websites where maintenance is true
            return acf?.maintenance && (matchesTitle || matchesDescription || matchesCategory || matchesClient);
        });

        // Filter by websites that have not been maintained for more than 30 days if toggle is on
        if (!enabled) {
            results = results.filter(website => daysSince(website.acf.last_maintenance) > 30);
        }

        // Sort by the longest time since maintenance
        results = results.sort((a, b) => {
            return daysSince(b.acf.last_maintenance) - daysSince(a.acf.last_maintenance);
        });

        setFilteredWebsites(results);
    }, [searchQuery, websites, enabled]);

    // Format date to ISO 8601 (yyyy-mm-dd) for backend storage
    const formatDateForBackend = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
        const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
        return `${year}-${month}-${day}`;
    };

    // Function to update the last maintenance date to today in both backend and local state
    const handleUpdateLastMaintenance = async (websiteId) => {
        const todayDate = formatDateForBackend(new Date()); // Don't use toISOString, just use the current date

        try {
            const token = localStorage.getItem("token");

            // Update backend ACF field for last_maintenance
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`,
                { acf: { last_maintenance: todayDate } }, // Send formatted date here
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Update local state to reflect the change in the UI immediately
            const updatedWebsites = websites.map(website =>
                website.id === websiteId
                    ? { ...website, acf: { ...website.acf, last_maintenance: todayDate } }
                    : website
            );

            // Update both websites and filteredWebsites states
            setWebsites(updatedWebsites);

            // Reapply the filter based on the toggle state after the update
            let results = updatedWebsites.filter(website => {
                const { title, acf } = website;
                const matchesTitle = title.rendered.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesDescription = acf?.description?.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesCategory = acf?.category?.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesClient = acf?.client_name?.toLowerCase().includes(searchQuery.toLowerCase());

                // Only include websites where maintenance is true
                return acf?.maintenance && (matchesTitle || matchesDescription || matchesCategory || matchesClient);
            });

            // Filter by websites that have not been maintained for more than 30 days if toggle is on
            if (!enabled) {
                results = results.filter(website => daysSince(website.acf.last_maintenance) > 30);
            }

            // Sort by the longest time since maintenance
            results = results.sort((a, b) => {
                return daysSince(b.acf.last_maintenance) - daysSince(a.acf.last_maintenance);
            });

            setFilteredWebsites(results);

            // Show success notification
            setNotification({ message: "Maintenance date updated successfully!", type: "success" });
            fetchMaintenanceCount();

        } catch (error) {
            console.error("Error updating last maintenance:", error);
            setNotification({ message: "Failed to update maintenance date.", type: "error" });
        }

        // Hide notification after 3 seconds
        setTimeout(() => setNotification(null), 3000);
    };


    return (
        <div className="bg-white shadow sm:rounded-lg">
            {/* Notification Display */}
            {notification && (
                <div className={`notification ${notification.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
                    {notification.message}
                </div>
            )}
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900">Maintenance</h1>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <Field className="flex items-center">
                            <Switch
                                checked={enabled}
                                onChange={setEnabled}
                                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-pink-600"
                            >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                            <Label as="span" className="ml-3 text-sm">
                                <span className="font-medium text-gray-900">Show All</span>{' '}
                            </Label>
                        </Field>
                    </div>
                </div>
            </div>

            {/* Conditionally render Search Bar */}
            {showSearchBar && (
                <div className="bg-white px-4 py-4 sm:px-6">
                    <input
                        type="text"
                        placeholder="Search websites..."
                        className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            )}

            {/* Website List */}
            <ul role="list" className="divide-y divide-gray-100 bg-white shadow rounded-lg">
                {filteredWebsites.map((website) => (
                    <li key={website.id} className="flex items-center justify-between gap-x-6 py-5 px-10">
                        <div className="min-w-0">
                            <div className="flex gap-x-6">
                                <img src={website.acf.logo} style={{ height: "50px", width: "50px" }} alt={`${website.title?.rendered || 'Untitled Website'} Logo`} />
                                <div className="flex flex-col">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold text-pink-700">
                                            <Link to={`/websites/${website.id}`} className="hover:underline">
                                                {website.title?.rendered || 'Untitled Website'}
                                            </Link>
                                        </p>
                                    </div>

                                    <div className="mt-1 flex items-center gap-x-2 text-xs text-gray-500">
                                        <p className="whitespace-nowrap">
                                            Last Maintenance: <time dateTime={website.acf.last_maintenance}>{website.acf.last_maintenance ? `${daysSince(website.acf.last_maintenance)} days ago` : 'N/A'}</time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className="bg-pink-600 hover:bg-pink-500 text-white px-3 py-1.5 rounded text-sm"
                            onClick={() => handleUpdateLastMaintenance(website.id)}
                        >
                            Done
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MaintanenceList;