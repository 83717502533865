import React, { useEffect, useState } from 'react';
import JobDrawer from './JobDrawer';

const JobsPage = () => {
  // State to store jobs and websites data
  const [jobs, setJobs] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // State for filter and search
  const [statusFilter, setStatusFilter] = useState(""); // New status filter state
  const [searchQuery, setSearchQuery] = useState("");   // New search query state

  // Fetch jobs and websites data when the component mounts
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs');
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    const fetchWebsites = async () => {
      try {
        const response = await fetch('https://api.crm.sugarprojects.com/wp-json/wp/v2/websites');
        const data = await response.json();
        setWebsites(data);
      } catch (error) {
        console.error('Error fetching websites:', error);
      }
    };

    fetchJobs();
    fetchWebsites();
  }, []);

  // Filtered jobs based on status and search query
  const filteredJobs = jobs.filter((job) => {
    const jobTitle = job.title.rendered.toLowerCase();
    const website = websites.find((site) => site.id === job.acf?.website?.[0]);
    const websiteTitle = website ? website.title.rendered.toLowerCase() : "";

    const matchesStatus = !statusFilter || job.acf.status === statusFilter;
    const matchesSearch = jobTitle.includes(searchQuery.toLowerCase()) || websiteTitle.includes(searchQuery.toLowerCase());

    return matchesStatus && matchesSearch;
  });

  // Function to handle adding a new job
  const handleAddJob = async (newJob) => {
    try {
      const token = localStorage.getItem("token");

      const jobData = {
        title: newJob.name, // Set job title
        status: "publish",  // Ensure the job is published
        acf: {
          website: [newJob.website]  // Set website as an array with selected website ID
        }
      };

      const response = await fetch('https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jobData),
      });

      if (response.ok) {
        const createdJob = await response.json();
        setJobs((prevJobs) => [createdJob, ...prevJobs]); 
      } else {
        console.error('Failed to add job:', await response.text());
      }
    } catch (error) {
      console.error('Error adding job:', error);
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Jobs</h1>
        <button
          onClick={() => setIsDrawerOpen(true)}
          className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
        >
          Add Job
        </button>
      </div>

      {/* Filter and Search Bar */}
      <div className="flex gap-4 mb-4">
        <input
          type="text"
          placeholder="Search by title or website"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="block w-5/6 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="block w-1/6 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
        >
          <option value="">All Statuses</option>
          <option value="upcoming" className='text-yellow-700'>Upcoming</option>
          <option value="ongoing" className='text-blue-700'>Ongoing</option>
          <option value="completed" className='text-green-700'>Completed</option>
        </select>
      </div>

      {/* Jobs list */}
      <ul role="list" className="divide-y divide-gray-200">
        {filteredJobs.map((job) => {
          let websiteId = "";
          if (Array.isArray(job.acf.website)) {
            websiteId = job.acf?.website[0] || null;
          } else {
            websiteId = job.acf?.website || null;
          }
          
          const website = websites.find((site) => site.id === websiteId);

          const status = job.acf.status;
          let statusTag;

          if (status === "ongoing") {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                Ongoing
              </p>
            );
          } else if (status === "upcoming") {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                Upcoming
              </p>
            );
          } else if (status === "completed") {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Completed
              </p>
            );
          } else {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Unknown
              </p>
            );
          }

          return (
            <li key={job.id} className="flex items-center justify-between py-5 px-8">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold text-gray-900">{job.title.rendered}</p>
                  {statusTag}
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs text-gray-500">
                  <p className="whitespace-nowrap">{website ? website.title.rendered : 'N/A'}</p>
                </div>
              </div>
              <a href={`/jobs/${job.id}`} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                View
              </a>
            </li>
          );
        })}
      </ul>

      {/* Job Drawer for adding a new job */}
      <JobDrawer 
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={handleAddJob}
        websites={websites}
      />
    </div>
  );
};

export default JobsPage;
