// src/ContactDrawer.js
import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon } from '@heroicons/react/24/solid'

const ContactDrawer = ({ isOpen, onClose, onSubmit }) => {
  const [contact, setContact] = useState({
    name: "",
    phone_1: "",
    phone_2: "",
    email: "",
    position: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(contact);
    setContact({ name: "", number: "", email: "", position: "" }); // Reset form
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-40" />
      <div className="fixed inset-0 overflow-hidden z-50">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition-all">
              <form onSubmit={handleSubmit} className="flex flex-col h-full divide-gray-200 bg-white shadow-xl">
                <div className="bg-pink-700 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold text-white">Add New Contact</DialogTitle>
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-md bg-pink-700 text-pink-200 hover:text-white"
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <p className="mt-1 text-sm text-pink-300">
                    Enter the details for the new contact.
                  </p>
                </div>
                <div className="flex-1 flex flex-col p-4">
                  <label className="block text-sm font-medium text-gray-900 mt-4">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                    required
                  />
                  <div>
                    <label htmlFor="phone-number" className="block text-sm/6 font-medium text-gray-900">
                      Phone Number 1
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor="country" className="sr-only">
                          Country
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country"
                          className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        >
                          <option>UK</option>
                          <option>IR</option>
                        </select>
                      </div>
                      <input
                        name="phone_1"
                        type="text"
                        placeholder="+1 (555) 987-6543"
                        value={contact.phone_1}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="phone-number" className="block text-sm/6 font-medium text-gray-900">
                      Phone Number 2
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor="country" className="sr-only">
                          Country
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country"
                          className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        >
                          <option>UK</option>
                          <option>IR</option>
                        </select>
                      </div>
                      <input
                        name="phone_2"
                        type="text"
                        placeholder="+1 (555) 987-6543"
                        value={contact.phone_2}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                      Email
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        name="email"
                        type="email"
                        placeholder="contact@example.com"
                        value={contact.email}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        required
                      />
                    </div>
                  </div>
                  <label className="block text-sm font-medium text-gray-900 mt-4">Position</label>
                  <input
                    type="text"
                    name="position"
                    value={contact.position}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                  />
                </div>
                <div className="flex justify-end p-4">
                  <button
                    type="button"
                    onClick={onClose}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ml-4 rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                  >
                    Add Contact
                  </button>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ContactDrawer;
