import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { useData } from './DataContext';
import axios from "axios"; // Import axios for network requests

// Default logo URL if none exists
const defaultLogoUrl =
  "https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/06/18162507/apple-touch-icon.png";

const WebsitesListComponent = ({
  delete: isDeleteEnabled,
  onDelete,
  showSearchBar,
  websites
}) => {
  const { deleteWebsite } = useData(); // Get websites and setWebsites from context
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredWebsites, setFilteredWebsites] = useState(websites);
  const [statusMap, setStatusMap] = useState({}); // State for online/offline statuses

  // Define the statuses and classNames utility function
  const statuses = {
    React: "text-white bg-blue-800 ring-blue-600/20",
    Wordpress: "text-white bg-blue-500",
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Filter websites based on search query
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const results = websites.filter((website) => {
      const { title, acf } = website;

      const matchesTitle = title?.rendered
        ?.toLowerCase()
        .includes(lowercasedQuery);
      const matchesDescription = acf?.description
        ?.toLowerCase()
        .includes(lowercasedQuery);
      const matchesCategory = acf?.category
        ?.toLowerCase()
        .includes(lowercasedQuery);
      const matchesClient = acf?.client_name
        ?.toLowerCase()
        .includes(lowercasedQuery);

      return (
        matchesTitle || matchesDescription || matchesCategory || matchesClient
      );
    });

    setFilteredWebsites(results);
  }, [searchQuery, websites]);

  // Function to check website status through the WordPress proxy endpoint
  async function checkWebsiteStatus(url) {
    try {
      const response = await axios.post(
        "https://api.crm.sugarprojects.com/wp-json/custom/v1/check-status",
        { url: url },
        { timeout: 5000 }
      );
      return response.data.status === 'up';
    } catch (error) {
      console.error(`Error checking status for ${url}:`, error);
      return false;
    }
  }

  // Run the status check for each website and update status map
  useEffect(() => {
    async function updateStatuses() {
      const statusUpdates = {};
      for (const website of websites) {
        const isOnline = await checkWebsiteStatus(website.acf.frontend_link);
        statusUpdates[website.id] = isOnline;
      }
      setStatusMap(statusUpdates); // Set all statuses at once
    }
    updateStatuses();
  }, [websites]);

  // Delete website handler (using onDelete function passed down)
  const handleDelete = (website) => {
    deleteWebsite(website.id, website.acf.client[0])
  };

  return (
    <div>
      {showSearchBar && (
        <div className="bg-white px-4 py-4 sm:px-6">
          <input
            type="text"
            placeholder="Search Websites..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500"
          />
        </div>
      )}

      <ul
        role="list"
        className="divide-y divide-gray-100 bg-white shadow rounded-lg"
      >
        {filteredWebsites.map((website) => (
          <li
            key={website.id}
            className="flex items-center justify-between gap-x-6 py-5 px-10"
          >
            <div className="min-w-0">
              <div className="flex gap-x-6">
                {/* Use the default logo if website.acf.logo is unavailable */}
                {/* Logo Container with Status Dot */}
                <div className="relative">
                  <img
                    src={website.acf.logo || defaultLogoUrl}
                    className="h-10 w-10 rounded-full"
                    alt={website.title?.rendered || 'Website Logo'}
                  />
                  {/* Status Dot */}
                  <span
                    className={`absolute bottom-0 right-0 h-4 w-4 rounded-full ${
                      statusMap[website.id] ? 'bg-green-500' : 'bg-red-500'
                    } border-2 border-white`}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-start gap-x-3">
                    <p className="text-base font-semibold text-pink-700">
                      <Link
                        to={`/websites/${website.id}`}
                        className="hover:underline"
                      >
                        {website.title?.rendered || "Untitled Website"}
                      </Link>
                    </p>

                    {/* Display Frontend and Backend Platforms */}
                    <div className="flex items-center gap-x-2">
                      {website.acf.frontend_platform && (
                        <p
                          className={classNames(
                            statuses[website.acf.frontend_platform] ||
                              statuses["In progress"],
                            "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                          )}
                        >
                          {website.acf.frontend_platform}
                        </p>
                      )}

                      {website.acf.backend_platform &&
                        website.acf.backend_platform !==
                          website.acf.frontend_platform && (
                          <p
                            className={classNames(
                              statuses[website.acf.backend_platform] ||
                                statuses["In progress"],
                              "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                            )}
                          >
                            {website.acf.backend_platform}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs text-gray-500">
                    <p className="whitespace-nowrap">
                      Created on{" "}
                      <time dateTime={website.acf.start_date}>
                        {website.acf.start_date}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {isDeleteEnabled && (
              <Menu as="div" className="relative flex-none">
                <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    aria-hidden="true"
                    className="h-5 w-5"
                  />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none"
                >
                  <MenuItem>
                    <a
                      href={`/websites/${website.id}`}
                      className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                    >
                      Edit
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href={website.acf.frontend_link}
                      target="_blank"
                      className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-3 py-1 text-sm/6 text-red-600 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                      onClick={() => handleDelete(website)} // Delete handler
                    >
                      Delete
                    </a>
                  </MenuItem>
                </MenuItems>
              </Menu>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WebsitesListComponent;