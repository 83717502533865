import { useState, useEffect } from 'react';
import '../styles/tailwind.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMaintenanceCount } from './MaintenanceCountContext';

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  Cog6ToothIcon,
  FolderIcon,
  WrenchIcon,
  PhoneIcon,
  UsersIcon,
  CalendarDateRangeIcon,
  XMarkIcon,
  PlusIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/solid';

const navigation = [
  { name: 'Clients', href: '/clients', icon: UsersIcon },
  { name: 'Websites', href: '/websites', icon: FolderIcon },
  { name: 'Maintenance', href: '/maintenance', icon: WrenchIcon },
  { name: 'Contacts', href: '/contacts', icon: PhoneIcon },
  { name: 'Jobs', href: '/jobs', icon: CalendarDateRangeIcon },
  { name: 'Leads', href: '/leads', icon: PlusIcon },
  { name: 'SOPs', href: '/sops', icon: ClipboardDocumentCheckIcon },
];

const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' }, // Change href to handle logout
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userImage, setUserImage] = useState(''); // State to store user image
  const navigate = useNavigate();
  const location = useLocation();
  const { maintenanceCount, fetchMaintenanceCount } = useMaintenanceCount();

  // Helper function to parse dates in dd/mm/yyyy format
  const parseDateDDMMYYYY = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // JavaScript months are 0-indexed
  };

  useEffect(() => {
    // Fetch maintenance count when component mounts
    fetchMaintenanceCount();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const image = localStorage.getItem('user_image'); // Retrieve user image from local storage
    if (image) {
      //console.log('Retrieved user image:', image); // Log the retrieved image path
      setUserImage(image);
    } else {
      //console.log('No user image found in local storage.');
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_image'); // Optionally clear the user image on logout
    navigate('/');
  };

  //console.log('User image path:', userImage); // Log the user image path before rendering
  
  return (
    <>
      <div className='bg-slate-50 min-h-lvh'>
        <Dialog open={sidebarOpen} onClose={() => setSidebarOpen(false)} className="relative z-50 lg:hidden">
          <DialogBackdrop className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear" />
          <div className="fixed inset-0 flex">
            <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out">
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Sugar Projects"
                    src="https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/06/18162507/apple-touch-icon.png"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <button
                              onClick={() => navigate(item.href)}
                              className={classNames(
                                location.pathname === item.href
                                  ? 'bg-gray-50 text-pink-700'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-pink-700',
                                'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6'
                              )}
                            >
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                              {item.name}
                              {item.name === 'Maintenance' && maintenanceCount > 0 && (
                                <div className="ml-2 px-4 py-4 text-white bg-red-300 rounded">({maintenanceCount})</div>
                              )}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <button
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-pink-700"
                        onClick={handleLogout}
                      >
                        <Cog6ToothIcon className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-pink-700" />
                        Sign out
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Your Company"
                src="https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/06/18162507/apple-touch-icon.png"
                className="h-8 w-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <button
                          onClick={() => navigate(item.href)}
                          className={classNames(
                            location.pathname.startsWith(item.href)
                              ? 'bg-gray-100 text-pink-700 w-full'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-pink-700 w-full',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                          {item.name}
                          {item.name === 'Maintenance' && maintenanceCount > 0 && (
                            <div className="ml-2 w-6 h-6 place-content-center text-white bg-red-400 rounded-full">{maintenanceCount}</div>
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <button
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-pink-700"
                    onClick={handleLogout}
                  >
                    <Cog6ToothIcon className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-pink-700" />
                    Sign out
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            <div className="flex-1 text-sm font-medium leading-6 text-gray-900">Sugarprojects CRM</div>

            <button type="button" className="-m-2.5 p-2.5 text-gray-700">
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            <Menu as="div" className="relative inline-block">
              <MenuButton className="-m-1.5 flex rounded-full bg-white p-1.5 text-gray-700 hover:bg-gray-50">
                <span className="sr-only">Open user menu</span>
                <img className="h-8 w-8 rounded-full" src={userImage || 'https://api.crm.sugarprojects.com/wp-content/uploads/2024/10/Screenshot-2024-11-01-164736.jpg'} alt="User" /> 
              </MenuButton>
              <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <MenuItem key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                      >
                        {item.name}
                      </a>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          </div>

          <main>
            <div className="px-4 py-6 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
