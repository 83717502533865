import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

// Create the context
const MaintenanceCountContext = createContext();

// Context provider component
export const MaintenanceCountProvider = ({ children }) => {
  const [maintenanceCount, setMaintenanceCount] = useState(0);

  // Function to fetch maintenance count from the backend
  const fetchMaintenanceCount = async () => {
    try {
      const response = await axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?_embed&acf_format=standard&per_page=100");
      const websites = response.data;
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      // Recalculate the count based on websites that need maintenance and last maintenance date
      const count = websites.reduce((acc, site) => {
        const needsMaintenance = site.acf.maintenance;
        const lastMaintenanceDate = parseDateDDMMYYYY(site.acf.last_maintenance);
        return needsMaintenance && lastMaintenanceDate < thirtyDaysAgo ? acc + 1 : acc;
      }, 0);

      console.log(websites)

      // Update the global context with the new maintenance count
      setMaintenanceCount(count);
    } catch (error) {
      console.error("Failed to fetch maintenance count:", error);
    }
  };

  // Helper function to parse dates in dd/mm/yyyy format (this can be used in the fetch function)
  const parseDateDDMMYYYY = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // JavaScript months are 0-indexed
  };

  return (
    <MaintenanceCountContext.Provider value={{ maintenanceCount, setMaintenanceCount, fetchMaintenanceCount }}>
      {children}
    </MaintenanceCountContext.Provider>
  );
};

// Custom hook to use the context
export const useMaintenanceCount = () => {
  return useContext(MaintenanceCountContext);
};