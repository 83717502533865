import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ContactListComponent from "./ContactListComponent";
import ContactDrawer from "./ContactDrawer";
import NotesComponent from "./NotesComponent"; // Import NotesComponent
import WebsiteChargesComponent from "./WebsiteChargesComponent";
import WebsiteGeneralComponent from "./WebsiteGeneralComponent";
import WordpressComponent from "./WordpressComponent";

// Default logo URL if none exists
const defaultLogoUrl =
  "https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/06/18162507/apple-touch-icon.png";

const WebsitePage = () => {
  const { websiteId } = useParams();
  const [website, setWebsite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notes, setNotes] = useState([]); // State to manage notes
  const [userImages, setUserImages] = useState({}); // State to hold user images
  const [clientTitle, setClientTitle] = useState(""); // State for the client title
  const [statusMap, setStatusMap] = useState({}); // State for online/offline statuses

  // Function to check website status through the WordPress proxy endpoint
  async function checkWebsiteStatus(url) {
    try {
      const response = await axios.post(
        "https://api.crm.sugarprojects.com/wp-json/custom/v1/check-status",
        { url: url },
        { timeout: 5000 }
      );
      return response.data.status === 'up';
    } catch (error) {
      console.error(`Error checking status for ${url}:`, error);
      return false;
    }
  }

  // Run the status check for the website and update status map
  useEffect(() => {
    async function updateStatuses() {
      const isOnline = await checkWebsiteStatus(website?.acf.frontend_link);
      setStatusMap({ [website.id]: isOnline }); // Set status for this website
    }

    if (website) {
      updateStatuses();
    }
  }, [website]);

  // Function to fetch user images
  const fetchUserImages = async () => {
    try {
      const response = await axios.get(
        "https://api.crm.sugarprojects.com/wp-json/wp/v2/users"
      );
      const images = {};
      response.data.forEach((user) => {
        if (user.acf && user.acf.user_image) {
          const firstName = user.name.split(" ")[0]; // Extract the first name
          images[firstName] = user.acf.user_image; // Map using only the first name
        }
      });
      setUserImages(images); // Store user images in state
    } catch (error) {
      console.error("Error fetching user images:", error);
    }
  };

  useEffect(() => {
    const fetchWebsite = async () => {
      try {
        const response = await axios.get(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`
        );
        console.log("Website Data:", response.data); // Log full response data
        const websiteData = response.data;

        setWebsite(websiteData);

        // Set the existing notes if available
        setNotes(websiteData.acf.notes || []);

        // Fetch user images
        await fetchUserImages();

        // Fetch client title if available
        if (websiteData.acf?.client && websiteData.acf.client[0]) {
          const clientId = websiteData.acf.client[0];
          const clientResponse = await axios.get(
            `https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${clientId}`
          );
          const clientTitle = clientResponse.data.title.rendered; // Get the client title
          setClientTitle(clientTitle); // Update the state with the client title
        }
      } catch (error) {
        console.error("Error fetching website data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWebsite();
  }, [websiteId]);

  // Handle adding a new note
  const handleAddNote = async (noteText) => {
    if (!noteText.trim()) return; // Avoid adding empty notes

    const currentDateTime = new Date().toISOString();
    const username = localStorage.getItem("username"); // Retrieve username from localStorage

    // Fetch the user image (fall back to a default image if not available)
    const userImage = userImages[username] || "/path/to/default/image.png"; // Replace with your default image path

    const updatedNotes = [
      ...notes,
      {
        note: noteText,
        time: currentDateTime,
        username: username,
        userImage: userImage, // Add the user image to the note
      },
    ];

    setNotes(updatedNotes); // Update the local notes state

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`,
        { acf: { notes: updatedNotes } },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Re-fetch user images to ensure new user's image is included
      await fetchUserImages();
    } catch (error) {
      console.error("Error adding new note:", error);
      setNotes(notes.slice(0, -1)); // Revert notes state if save fails
    }
  };

  // Handle adding a new contact
  const handleAddContact = async (contact) => {
    try {
      const token = localStorage.getItem("token");
      const newContactWithLogo = { ...contact, logo: website.acf?.logo };

      // Use an empty array as a fallback if website.acf.contacts is null or undefined
      const updatedContacts = [
        ...(website.acf.contacts || []),
        newContactWithLogo,
      ];

      const response = await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`,
        { acf: { contacts: updatedContacts } },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setWebsite((prevWebsite) => ({
        ...prevWebsite,
        acf: { ...prevWebsite.acf, contacts: response.data.acf.contacts },
      }));
      setIsDrawerOpen(false); // Close the drawer after submission
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      {/* Website Image and Status */}
      <div className="flex items-center gap-x-6">
        <div className="relative">
          <img
            src={website.acf.logo || defaultLogoUrl}
            className="h-10 w-10 rounded-full"
            alt={website.title?.rendered || "Website Logo"}
          />
          <span
            className={`absolute bottom-0 right-0 h-4 w-4 rounded-full ${
              statusMap[website.id] ? "bg-green-500" : "bg-red-500"
            } border-2 border-white`}
          />
        </div>

        <div>
          <h2 className="text-2xl font-semibold">{website.title?.rendered}</h2>
          <p>{website.content?.rendered}</p>
        
      
       
          {clientTitle ? (
            <p className="text-sm font-medium text-gray-600">
              
              <a
                href={`/clients/${website.acf.client[0]}`} // Use the client ID to create the URL
                className="text-pink-500 hover:underline"
              >
                {clientTitle}
              </a>
            </p>
          ) : (
            <p className="text-lg font-medium text-gray-600">
              Not Available
            </p>
          )}
          </div>
      </div>
      
      {/* Render the list of contacts */}
      <WebsiteChargesComponent website={website} />
      <div className="flex space-x-4 w-full p-0">
        {/* Left Component */}
        <div className="w-1/2">
          <WebsiteGeneralComponent website={website} />
        </div>

        {/* Right Component */}
        <div className="w-1/2 bg-white p-6 rounded-md shadow-md">
          <WordpressComponent externalSiteApiLink={"https://api.crm.sugarprojects.com"} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="text-xl font-semibold text-gray-900 mt-6">Contacts:</h3>
        <button
          onClick={() => setIsDrawerOpen(true)}
          className="rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 mt-6"
        >
          Add New Contact
        </button>
      </div>
      <ContactListComponent websites={[website]} unique={false} cols={3} />
      <ContactDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={handleAddContact}
      />
      {/* Render the list of notes */}
      <h3 className="text-xl font-semibold text-gray-900 mt-6">Notes:</h3>
      <NotesComponent
        notes={notes}
        handleAddNote={handleAddNote}
        userImages={userImages}
      />
    </div>
  );
};

export default WebsitePage;
