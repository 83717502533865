import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const NotesComponent = ({ notes, handleAddNote, userImages }) => {
  // Accept userImages as a prop
  const [newNote, setNewNote] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const notesPerPage = 10;

  // Calculate the total number of pages
  const totalPages = Math.ceil(notes.length / notesPerPage);

  // Get the notes to display for the current page
  const startIdx = (currentPage - 1) * notesPerPage;
  const currentNotes = notes.slice(startIdx, startIdx + notesPerPage);

  // Pagination handlers
  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const onSubmit = (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    if (!newNote.trim()) return; // Prevent adding empty notes
    handleAddNote(newNote); // Call the parent method with the new note
    setNewNote(""); // Clear the input field
  };

  //console.log('User Images:', userImages);
  //console.log('Notes:', notes);

  return (
    <div className="bg-white mt-6 p-10 pt-16 pb-16 rounded-lg shadow-lg">
      <ul role="list" className="space-y-6">
        {notes.map((note, idx) => {
          // Log note and the corresponding image URL
          const imageUrl = userImages[note.username] || 'https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/06/18162507/apple-touch-icon.png';
          //console.log(`Note: ${note.note}, Username: ${note.username}, Image URL: ${imageUrl}`);

          return (
            <li key={idx} className="relative flex gap-x-4">
              <div className="relative flex pt-6 h-10 w-10 items-center justify-center bg-white">
                <img
                  src={imageUrl}
                  alt={note.username}
                  className="h-10 w-10 rounded-full mr-2"
                />
              </div>
              <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                <div className="flex justify-between">
                  <p className="font-medium text-gray-900">{note.note}</p>
                  <time className="text-xs text-gray-500">
                    {new Date(note.time).toLocaleString()}
                  </time>
                </div>
                <p className="text-xs text-gray-600">
                  Added By: {note.username}
                </p>
              </div>
            </li>
          );
        })}
      </ul>

      {/* Pagination Controls */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={goToPreviousPage}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </button>
          <button
            onClick={goToNextPage}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{startIdx + 1}</span> to{" "}
              <span className="font-medium">
                {Math.min(startIdx + notesPerPage, notes.length)}
              </span>{" "}
              of <span className="font-medium">{notes.length}</span> results
            </p>
          </div>
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            >
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
              </button>
              {[...Array(totalPages).keys()].map((_, i) => (
                <button
                  key={i}
                  onClick={() => goToPage(i + 1)}
                  className={`${
                    currentPage === i + 1
                      ? "z-10 bg-pink-700 text-white"
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  } relative inline-flex items-center px-4 py-2 text-sm font-semibold`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </nav>
          </div>
        </div>
      </div>

      {/* New note form */}
      <div className="mt-6 flex items-center">
        <form onSubmit={onSubmit} className="flex w-full ml-10">
          <div className="flex-grow pr-2">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              id="comment"
              rows={2}
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Add your comment..."
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-700 sm:text-sm/6"
            />
          </div>

          <div className="flex-none w-1/10 flex items-center justify-right ml-2">
            <button
              type="submit"
              className="rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
            >
              Add Note
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NotesComponent;
