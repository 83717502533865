// src/components/WordpressComponent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const WordpressComponent = ({ externalSiteApiLink }) => {

    const getToken = async () => {
        try {
            const response = await axios.post(
                `${externalSiteApiLink}/wp-json/jwt-auth/v1/token`,
                {
                    username: process.env.REACT_APP_USERNAME,
                    password: process.env.REACT_APP_PASSWORD,
                }
            );
      
            const { token } = response.data;
            return token;
        } catch (error) {
            console.error("Failed to get token:", error.response ? error.response.data : error.message);
            return null;
        }
    };

    const fetchPlugins = async () => {
        const token = await getToken();
        
        if (!token) {
            console.error("Token retrieval failed.");
            return [];
        }
        
        try {
            const response = await axios.get(`${externalSiteApiLink}/wp-json/wp/v2/plugins`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response.data)
            return response.data;
        } catch (error) {
            console.error('Error fetching plugins:', error);
            return [];
        }
    };
    
    const [plugins, setPlugins] = useState([]);

    useEffect(() => {
        const getPlugins = async () => {
            const pluginData = await fetchPlugins();
            setPlugins(pluginData);
        };
        getPlugins();
    }, []);

    return (
        <div className="">
            <h1 className="text-2xl font-semibold text-gray-800 mb-6">Installed Plugins</h1>
            <ul className="space-y-4">
                {plugins.length > 0 ? (
                    plugins.map((plugin) => (
                        <li
                            key={plugin.plugin}
                            className="p-4 border border-gray-200 rounded-lg shadow-sm flex justify-between items-center bg-gray-50"
                        >
                            <div className="text-md font-medium text-gray-700">{plugin.name}</div>
                            <div className="text-sm text-gray-500">Version {plugin.version}</div>
                        </li>
                    ))
                ) : (
                    <li className="text-gray-500">No plugins found.</li>
                )}
            </ul>
        </div>
    );
};

export default WordpressComponent;