// src/components/ContactsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

const ContactsPage = () => {
  const [groupedContacts, setGroupedContacts] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.crm.sugarprojects.com/wp-json/wp/v2/websites', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const uniqueContactsMap = new Map();
        response.data.forEach((website) => {
          if (website.acf && Array.isArray(website.acf.contacts)) {
            const logoUrl = website.acf.logo;
            const websiteUrl = website.acf.frontend_link;

            website.acf.contacts.forEach((contact) => {
              if (contact && contact.email) {
                uniqueContactsMap.set(contact.email, {
                  ...contact,
                  logo: logoUrl,
                  websiteUrl: websiteUrl,
                });
              }
            });
          }
        });

        const uniqueContacts = Array.from(uniqueContactsMap.values());

        const grouped = uniqueContacts.reduce((acc, contact) => {
          const letter = contact.name.charAt(0).toUpperCase();
          if (!acc[letter]) {
            acc[letter] = [];
          }
          acc[letter].push(contact);
          return acc;
        }, {});

        Object.keys(grouped).forEach((letter) => {
          grouped[letter].sort((a, b) => a.name.localeCompare(b.name));
        });

        setGroupedContacts(grouped);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredContacts = Object.keys(groupedContacts).reduce((acc, letter) => {
    const filtered = groupedContacts[letter].filter(contact => {
      const { name, email, number, position, websiteUrl } = contact;
      return (
        name.toLowerCase().includes(searchQuery) ||
        email.toLowerCase().includes(searchQuery) ||
        (number && number.toLowerCase().includes(searchQuery)) ||
        (position && position.toLowerCase().includes(searchQuery)) ||
        websiteUrl.includes(searchQuery)
      );
    });
    if (filtered.length) acc[letter] = filtered;
    return acc;
  }, {});

  return (
    <div className="h-full w-full overflow-y-auto bg-white shadow">
      <h1 className="text-2xl font-semibold leading-6 text-gray-900 mt-6 ml-6">Contacts</h1>
      <div className="bg-white px-4 py-4 sm:px-6">
        <input
          type="text"
          placeholder="Search contacts..."
          className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      <nav aria-label="Directory">
        {Object.keys(filteredContacts).sort().map((letter) => (
          <div key={letter} className="relative">
            <div className="sticky top-0 z-10 bg-gray-50 px-3 py-1.5 text-sm font-semibold text-gray-900 border-t border-b border-gray-200">
              <h3>{letter}</h3>
            </div>
            <ul role="list" className="divide-y divide-gray-100">
              {filteredContacts[letter].map((contact) => (
                <li key={contact.email} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                  <div className="flex min-w-0 gap-x-4">
                    {contact.logo ? (
                      <img
                        src={contact.logo}
                        alt="Website Logo"
                        className="h-12 w-12 flex-none rounded-full bg-gray-100 object-cover"
                      />
                    ) : (
                      <div className="flex items-center justify-center h-12 w-12 flex-none rounded-full bg-pink-500 text-white font-bold">
                        {contact.name.charAt(0).toUpperCase()}
                      </div>
                    )}
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold text-gray-900">
                        <a href={`mailto:${contact.email}`} className="hover:underline">
                          {contact.name}
                        </a>
                      </p>
                      <p className="mt-1 flex text-xs text-pink-600">
                        <a href={`mailto:${contact.email}`} className="relative truncate hover:underline">
                          {contact.email}
                        </a>
                      </p>
                      {contact.websiteUrl && (
                        <p className="mt-1 flex text-xs text-pink-600">
                          <a
                            href={contact.websiteUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline"
                          >
                            {contact.websiteUrl}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm text-gray-900">{contact.position || "No Position"}</p>
                      {contact.phone_1 ? (
                        <p className="mt-1 text-xs text-gray-500">
                          {contact.phone_1}
                        </p>
                      ) : (
                        <div>
                        </div>
                      )}
                      {contact.phone_2 ? (
                        <p className="mt-1 text-xs text-gray-500">
                          {contact.phone_2}
                        </p>
                      ) : (
                        <div>
                        </div>
                      )}
                    </div>
                    <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default ContactsPage;