import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ClientsPage from './components/ClientsPage'; 
import ClientPage from './components/ClientPage';
import WebsitePage from './components/WebsitePage';
import WebsitesPage from './components/WebsitesPage';
import MaintenancePage from './components/MaintenancePage';
import ContactsPage from './components/ContactsPage';
import JobsPage from './components/JobsPage';
import LeadsPage from './components/LeadsPage';
import JobPage from './components/JobPage';
import SOPsPage from './components/SOPsPage';
import { DataProvider } from './components/DataContext';
import './styles/tailwind.css';
import { MaintenanceCountProvider } from './components/MaintenanceCountContext'; // Import the provider

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        // Wrap the entire app in the MaintenanceCountProvider
        <DataProvider>
            <MaintenanceCountProvider>
                <Routes>
                    {/* Login Route */}
                    <Route
                        path="/"
                        element={<Login setIsAuthenticated={setIsAuthenticated} />}
                    />

                    {/* Protected Dashboard Layout */}
                    {isAuthenticated ? (
                        <>
                            <Route path="/" element={<Dashboard />}>
                                <Route path="/clients" element={<ClientsPage />} />
                                <Route path="/clients/:clientId" element={<ClientPage />} />
                                <Route path="/websites" element={<WebsitesPage />} />
                                <Route path="/websites/:websiteId" element={<WebsitePage />} />
                                <Route path="/maintenance" element={<MaintenancePage />} />
                                <Route path="/contacts" element={<ContactsPage />} />
                                <Route path="/jobs" element={<JobsPage />} />
                                <Route path='/jobs/:jobId' element={<JobPage />} />
                                <Route path="/leads" element={<LeadsPage />} />
                                <Route path="/sops" element={<SOPsPage />} />
                            </Route>
                        </>
                    ) : (
                        <Route path="/" element={<Navigate to="/" replace />} />
                    )}
                </Routes>
            </MaintenanceCountProvider>
        </DataProvider>
    );
};

export default App;


