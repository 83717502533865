import React, { useState } from "react";
import ClientsList from "./ClientsList"; // The renamed list component
import ClientPage from "./ClientPage";

const ClientsPage = () => {
  const [selectedClientId, setSelectedClientId] = useState(null);

  return (
    <div className="flex">
      {/* Sidebar with ClientsList */}
      <div className="w-[30%] min-h-[90vh] pr-4">
        <ClientsList onSelectClient={setSelectedClientId} />
      </div>

      {/* Divider */}
      <div className="w-px bg-gray-300 mx-4"></div>

      {/* Client Details */}
      <div className="w-[70%] min-h-[90vh] p-4">
        {selectedClientId ? (
          <ClientPage clientId={selectedClientId} />
        ) : (
          <p className="text-center text-gray-700">
            Select a client to view details
          </p>
        )}
      </div>
    </div>
  );
};

export default ClientsPage;