// src/components/MaintenancePage.js
import React, { useEffect, useState } from 'react';
import MaintanenceList from './MaintanenceList';
import axios from "axios";

const MaintenancePage = () => {
  const [websites, setwebsites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchwebsites = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          "https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?_embed&acf_format=standard&per_page=100"
        );

        if (Array.isArray(response.data)) {
          setwebsites(response.data);
        } else {
          setwebsites([]);
        }

        // Check if there's more data available based on headers
        const totalPages = response.headers['x-wp-totalpages'];
        if (totalPages > 1) {
          // Fetch additional pages if necessary
          for (let page = 2; page <= totalPages; page++) {
            const additionalResponse = await axios.get(
              `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?_embed&acf_format=standard&per_page=100&page=${page}`
            );
            setwebsites((prevwebsites) => [...prevwebsites, ...additionalResponse.data]);
          }
        }

      } catch (err) {
        setError("Failed to fetch websites");
      } finally {
        setLoading(false);
      }
    };

    fetchwebsites();
  }, []);

  return (
    <div>
      <MaintanenceList websites={websites} setWebsites={setwebsites} showSearchBar={true}/>
    </div>
  );
};

export default MaintenancePage;