import React from 'react';
import ReactDOM from 'react-dom/client'; // Use react-dom/client instead of react-dom
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Create a root with createRoot instead of using ReactDOM.render
const root = ReactDOM.createRoot(rootElement);

// Render the app
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);