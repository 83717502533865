import React, { useState } from "react";
import axios from "axios";

const WebsiteChargesComponent = ({ website }) => {
  const [charges, setCharges] = useState(website.acf.charges || []);
  const [chargesToDelete, setChargesToDelete] = useState([]);
  const [maintenance, setMaintenance] = useState(website.acf.maintenance || false);
  const [notification, setNotification] = useState(null);

  // Handler for input change in each field
  const handleInputChange = (index, field, value) => {
    const updatedCharges = charges.map((charge, i) =>
      i === index ? { ...charge, [field]: value } : charge
    );
    setCharges(updatedCharges);
  };

  // Toggle maintenance status
  const handleMaintenanceToggle = () => {
    setMaintenance(!maintenance);
  };

  // Add new charge row
  const handleAddCharge = () => {
    setCharges([...charges, { charge: "", details: "", start_date: "", end_date: "", price: "", reminder: false }]);
  };

  // Delete charge row
  const handleDeleteCharge = (index) => {
    const updatedCharges = [...charges];
    const [deletedCharge] = updatedCharges.splice(index, 1);
    setCharges(updatedCharges);
    if (deletedCharge && deletedCharge.id) {
      setChargesToDelete([...chargesToDelete, deletedCharge.id]);
    }
  };

  // Notification component
  const Notification = ({ message, type }) => (
    <div className={`fixed top-[70px] right-4 p-4 rounded shadow-lg ${type === "success" ? "bg-green-500" : "bg-red-500"} text-white`}>
      {message}
    </div>
  );

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");

      // Update the backend with the new charges and maintenance status
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
        { 
          acf: { 
            charges, 
            maintenance 
          } 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Delete removed charges from the backend
      await Promise.all(
        chargesToDelete.map((chargeId) =>
          axios.delete(`https://api.crm.sugarprojects.com/wp-json/wp/v2/charges/${chargeId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
        )
      );

      setChargesToDelete([]);
      setNotification({ message: "Charges updated successfully!", type: "success" });

      // Hide notification after 3 seconds
      setTimeout(() => setNotification(null), 3000);

    } catch (error) {
      console.error("Error updating charges:", error);
      setNotification({ message: "Failed to save charges.", type: "error" });

      // Hide notification after 3 seconds
      setTimeout(() => setNotification(null), 3000);
    }
  };

  console.log(website)

  return (

    <div className="px-6 py-6 mt-4 mb-4 bg-white shadow pt-8 pb-12 bg-white rounded-md shadow-md">
      {notification && <Notification message={notification.message} type={notification.type} />} {/* Display notification */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">Website Charges</h1>
          <div className="mt-2 text-sm text-gray-700 flex items-center gap-2">
            Maintenance: 
            <input
              type="checkbox"
              checked={maintenance}
              onChange={handleMaintenanceToggle} // Toggle maintenance state
              className="focus:ring-0"
              style={{ color: "#db2777" }}
            />
          </div>

        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={handleSave}
            className="rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
          >
            Save Charges
          </button>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Charge</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Details</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Start Date</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">End Date</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Price</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Reminder</th>
                  <th className="relative py-3.5 pl-3 pr-4 sm:pr-0"><span className="sr-only">Delete</span></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {charges.map((charge, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <input
                        type="text"
                        className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
                        value={charge.charge}
                        onChange={(e) => handleInputChange(index, "charge", e.target.value)}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="text"
                        className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
                        value={charge.details}
                        onChange={(e) => handleInputChange(index, "details", e.target.value)}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="date"
                        className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
                        value={charge.start_date}
                        onChange={(e) => handleInputChange(index, "start_date", e.target.value)}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="date"
                        className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
                        value={charge.end_date}
                        onChange={(e) => handleInputChange(index, "end_date", e.target.value)}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[10rem]">
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">£</span>
                        </div>
                        <input
                          type="number"
                          placeholder="0.00"
                          value={charge.price}
                          onChange={(e) => handleInputChange(index, "price", e.target.value)}
                          className="block w-full  pl-7 pr-12 placeholder:text-gray-400 sm:text-sm/6 w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span id="price-currency" className="text-gray-500 sm:text-sm">
                            GBP
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                      <input
                        type="checkbox"
                        checked={charge.reminder}
                        onChange={(e) => handleInputChange(index, "reminder", e.target.checked)}
                        className="focus:ring-0"
                        style={{ color: "#db2777" }}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <button onClick={() => handleDeleteCharge(index)} className="text-red-500 hover:text-red-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5 inline"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-2 flex justify-end">
        <button
          onClick={handleAddCharge}
          type="button"
          className="px-3 py-2 font-semibold text-pink-600 hover:text-pink-400"
        >
          Add Charge
        </button>
      </div>
    </div>
  );
};

export default WebsiteChargesComponent;
