import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

// Create the Data Context
const DataContext = createContext();

// Context provider component
export const DataProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [leads, setLeads] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [sops, setSops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch all data from the backend
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch all data concurrently
      const [clientsData, websitesData, leadsData, jobsData, sopsData] = await Promise.all([
        axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/clients", { headers }),
        axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/websites", { headers }),
        axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/leads", { headers }),
        axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs", { headers }),
        axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/sops", { headers })
      ]);

      // Store data in state
      setClients(clientsData.data);
      setWebsites(websitesData.data);
      setLeads(leadsData.data);
      setJobs(jobsData.data);
      setSops(sopsData.data);

    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  // Function to add a new client
  const addClient = async (clientName) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(
        'https://api.crm.sugarprojects.com/wp-json/wp/v2/clients',
        { title: clientName, status: 'publish' },
        { headers }
      );
      setClients((prevClients) => [...prevClients, response.data]);
      return true;
    } catch (error) {
      console.error("Error adding client:", error);
      setError("Failed to add client");
      return false;
    }
  };


    const addWebsite = async (name, client) => {
      try {
        const token = localStorage.getItem('token');
  
        // Prepare the data for the POST request, including ACF fields
        const requestData = {
          title: name,
          status: 'publish',
          acf: {
            client,  // Assign the client ID as an ACF field
          },
        };
  
        // Step 1: Create the new website and retrieve its ID
        const websiteResponse = await axios.post(
          "https://api.crm.sugarprojects.com/wp-json/wp/v2/websites",
          requestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        const newWebsiteId = websiteResponse.data.id; // Get the new website's ID
        console.log(client)
  
        // Step 2: Fetch the existing client to retrieve its current websites list
        const clientResponse = await axios.get(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${client}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        // Extract existing websites and add the new website ID
        const existingWebsites = clientResponse.data.acf.websites || [];
        const updatedWebsites = [...existingWebsites, newWebsiteId];
  
        // Step 3: Update the client’s websites field to include the new website
        await axios.post(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${client}`,
          {
            acf: {
              websites: updatedWebsites,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        // Update the state with the new website data and close the drawer
        setWebsites((prevWebsites) => [websiteResponse.data, ...prevWebsites]);
  
      } catch (err) {
        console.error('Error adding website:', err);
        setError('Failed to add website');
      }
    };



  

  // Function to add a note to a client or website
  const addNote = async (entityId, entityType, noteText) => {
    if (!noteText.trim()) return; // Avoid adding empty notes

    const currentDateTime = new Date().toISOString();
    const username = localStorage.getItem("username"); // Retrieve username from localStorage

    const updatedNote = {
      note: noteText,
      time: currentDateTime,
      username: username,
      userImage: "/path/to/default/image.png", // Fallback image
    };

    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      // Determine the URL and data structure based on whether it's a client or website
      const entity = entityType === 'client' 
        ? clients.find(client => client.id === entityId)
        : websites.find(website => website.id === entityId);

      if (!entity) throw new Error('Entity not found');

      const updatedEntity = {
        ...entity,
        acf: {
          ...entity.acf,
          notes: [...(entity.acf?.notes || []), updatedNote], // Append new note
        },
      };

      // Send API request to update notes
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/${entityType}s/${entityId}`,
        {
          acf: {
            notes: updatedEntity.acf.notes,
          },
        },
        { headers }
      );

      // Update the local state for clients or websites
      if (entityType === 'client') {
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.id === entityId ? updatedEntity : client
          )
        );
      } else if (entityType === 'website') {
        setWebsites((prevWebsites) =>
          prevWebsites.map((website) =>
            website.id === entityId ? updatedEntity : website
          )
        );
      }

    } catch (error) {
      console.error("Error adding new note:", error);
    }
  };

  // Function to delete a client
  const deleteClient = async (clientId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${clientId}`, { headers });
      setClients((prevClients) => prevClients.filter(client => client.id !== clientId));
    } catch (error) {
      console.error("Error deleting client:", error);
      setError("Failed to delete client");
    }
  };

  // Fetch data when the provider mounts
  useEffect(() => {
    fetchData();
  }, []);

  const deleteWebsite = async (websiteId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setWebsites((prevWebsites) => prevWebsites.filter((website) => website.id !== websiteId));
    } catch (err) {
      console.error('Error deleting website:', err);
      setError('Failed to delete website');
    }
  };

  return (
    <DataContext.Provider value={{
      clients,
      websites,
      leads,
      jobs,
      sops,
      loading,
      error,
      fetchData,
      addClient,
      addWebsite,
      deleteClient,
      deleteWebsite,
      addNote,
      setError
    }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the context
export const useData = () => useContext(DataContext);